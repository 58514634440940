'use strict'

/**
 * @TProductType
 * 
 * Always use the singular form of the product type.
 * the product type is the same as the handle.
 */
export type TProductType = 
  'Art Print' |
  'Canvas Print' |
  'Framed Print' |
  'Wood Print' |
  'Greeting Card' |
  'Mug' |
  'Tote Bag' |
  'Spiral Notebook' |
  'Phone Case';

export type TCategories = 'Wall Art' | 'Accessories' | 'Clothes';

export const Categories = {
  'Wall Art': {
    products: ['Art Print', 'Canvas Print', 'Framed Print', 'Wood Print'],
  },
  Accessories: {
    products: ['Phone Case', 'Mug', 'Tote Bag', 'Spiral Notebook'],
  },
  Clothes: {
    products: ['Socks', 'Hoodies', 'Baby Onesies']
  }
}
  
export interface IProduct {
  productType: TProductType;
  label: string;
  handle: string;
  image: string;
  minPrice: string;
}

export const getProduct = (p: TProductType): IProduct => {
  return ProductManifest[p];
}

export const DefaultType: TProductType = 'Canvas Print';

export const ProductManifest: {[t in TProductType]: IProduct} = {
// export const ProductManifest: {[key: TProductType]: IProduct} = {
  'Art Print': {
    productType: 'Art Print',
    label: 'Art Print',
    handle: 'art-print',
    image: '/static/img/abstract-square.jpg',
    minPrice: '$21'
  },
  'Canvas Print': {
    productType: 'Canvas Print',
    label: 'Canvas',
    handle: 'canvas',
    image: '/static/img/canvas-square.png',
    minPrice: '$31'
  },
  'Wood Print': {
    productType: 'Wood Print',
    label: 'Wood Print',
    handle: 'wood-print',
    image: '/static/img/wood-print.jpg',
    minPrice: '$35'
  },
  'Framed Print': {
    productType: 'Framed Print',
    label: 'Framed Art Print',
    handle: 'framed-print',
    image: '/static/img/frames-square.jpg',
    minPrice: '$54'
  },
  'Mug': {
    productType: 'Mug',
    label: 'Coffee Mugs',
    handle: 'coffee-mug',
    image: '/static/img/coffee-square.jpg',
    minPrice: '$21'
  },
  'Phone Case': {
    productType: 'Phone Case',
    label: 'Phone Case',
    handle: 'phone-case',
    image: '/static/img/phone-case.jpg',
    minPrice: '$21'
  },
  'Greeting Card': {
    productType: 'Greeting Card',
    label: 'Greeting Card',
    handle: 'greeting-card',
    image: '/static/img/greeting-card.jpg',
    minPrice: '$15'
  },
  'Tote Bag': {
    productType: 'Tote Bag',
    label: 'Tote Bag',
    handle: 'tote-bag',
    image: '/static/img/tote-bag.jpg',
    minPrice: '$21'
  },
  'Spiral Notebook': {
    productType: 'Spiral Notebook',
    label: 'Spiral Notebook',
    handle: 'spiral-notebook',
    image: '/static/img/journal.jpg',
    minPrice: '$21'
  }
}

export const all = (): IProduct[] => {
  return (Object.keys(ProductManifest) as TProductType[]).map(k => {
    return getProduct(k);
  })
}

/**
 * extracts a product from the handle that would be used in a url
 */
export const fromUrl = (handle: string): IProduct | undefined => {
  let k = (Object.keys(ProductManifest) as TProductType[]).filter((k: TProductType) => getProduct(k).handle === handle).pop();
  return (k) ? getProduct(k) : undefined;
}

/**
 * Converts a productType to the url
 */
export const productToUrl = (p: TProductType): string => {
  return getProduct(p).handle;
}