
    
// NOTE: This require will be replaced with `@sentry/browser` when
// process.browser === true thanks to the webpack config in next.config.js
const Sentry = require('@sentry/node');
import Cookie from 'js-cookie';

export default ({ release }) => {
  const sentryOptions = {
    // dsn: process.env.SENTRY_DSN,
    dsn: 'https://8c462fd202084a9db912cb9190fb6078@sentry.io/1412006',
    release,
    maxBreadcrumbs: 50,
    attachStacktrace: true
  }

  // // When we're developing locally
  // if (process.env.NODE_ENV !== 'production') {
  //   /* eslint-disable-next-line global-require */
  //   const sentryTestkit = require('sentry-testkit')
  //   const { sentryTransport } = sentryTestkit()

  //   // Don't actually send the errors to Sentry
  //   sentryOptions.transport = sentryTransport

  //   // Instead, dump the errors to the console
  //   sentryOptions.integrations = [new Sentry.Integrations.Debug({
  //     // Trigger DevTools debugger instead of using console.log
  //     debugger: false
  //   })]
  // }

  Sentry.init(sentryOptions)

  return {
    Sentry,
    captureException: (err, extra?: { req: any} ) => {
      const req = extra ? extra.req : {};

      // Sentry expects an Error object, however some Thrown objects are just plain objects.
      const wrappedErr = (err instanceof Error || typeof err === 'string') ? err : new Error(JSON.stringify(err));

      console.log('capturing exception');
      Sentry.configureScope((scope) => {
        if ((typeof wrappedErr !== 'string' && wrappedErr.message)) {
          // De-duplication currently doesn't work correctly for SSR / browser errors
          // so we force deduplication by error message if it is present
          scope.setFingerprint([wrappedErr.message])
        }

        if (err.statusCode) {
          scope.setExtra('statusCode', err.statusCode)
        }

        // @ts-ignore
        if (process.browser) {
          // @ts-ignore
          scope.setTag('ssr', false)

          // On client-side we use js-cookie package to fetch it
          const sessionId = Cookie.get('_ga')
          if (sessionId) {
            scope.setUser({ id: sessionId })
          }
        } else {
          // @ts-ignore
          scope.setTag('ssr', true)
          scope.setExtra('url', req.url)
          scope.setExtra('method', req.method)
          scope.setExtra('headers', req.headers)
          scope.setExtra('params', req.params)
          scope.setExtra('query', req.query)

          // On server-side we take session cookie directly from request
          if (req.cookies.sid) {
            scope.setUser({ id: req.cookies.sid })
          }
        }
      })

      /**
       * Sentry expects an actual Error object, not just any primitive or other object.
       */
      if((err instanceof Error || typeof err === 'string')) {
        Sentry.captureException(err)
      } else {
        // Not sure if this is totally right...
        Sentry.captureMessage(JSON.stringify(err))
      }
      return 
    }
  }
}