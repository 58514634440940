'use strict'

import React from 'react';
import { GET_CHECKOUT_ID, GET_CHECKOUT } from '../../../lib/providers/apolloshopify/clientstate';
import { Display as DataCheckoutButton, Loading as LoadingCheckoutButton, Error as ErrorCheckoutButton } from '../components/checkoutbutton';
import { CART_URI } from '../../../config';
import { Button } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useQuery } from '@apollo/react-hooks';

//TODO: Add data response type.
interface ICheckoutButtonIdData {
  checkoutId: string;
}

interface ICheckoutVariables {
  id: string;
}

interface ICheckoutResponse {
  node: {
    id: string;
    ready: boolean;
    webUrl: string;
    completedAt: null | string;
  }
}

interface IProps {
  disabled?: boolean;
}

interface ICheckoutVariables {
  id: string;
}

interface ICheckoutResponse {
  node: {
    id: string;
    ready: boolean;
    webUrl: string;
    completedAt: null | string;
  }
}

export const CheckoutButton = ({ disabled = false }: IProps) => {
  const { loading, data, error } = useQuery<ICheckoutButtonIdData>(GET_CHECKOUT_ID, {
    ssr: false
  });
  const checkoutButtonQuery = useQuery<ICheckoutResponse, ICheckoutVariables>(GET_CHECKOUT, {
    variables: {
      id: (data) ? data.checkoutId : null
    },
    skip: !(data && data.checkoutId),
    ssr: false,
    fetchPolicy: 'network-only'
  });

  if(checkoutButtonQuery.error) {
    return (<div>Error</div>)
  } if(checkoutButtonQuery.data) {
    if(loading) {
      return <LoadingCheckoutButton />
    } else if(error) {
      return <ErrorCheckoutButton />
    } else {
      // Shopify returns a myshopify uri for checkout. We need to replace it with our branded web uri
      const initialWebUrl = checkoutButtonQuery.data!.node.webUrl;
      const brandedWebUrl = initialWebUrl.replace("abstract-artwork.myshopify.com", CART_URI);
      return <DataCheckoutButton disabled={disabled} webUrl={brandedWebUrl} ready={checkoutButtonQuery.data.node.ready} />;
    }
  } else {
    return (
      <Button
        disabled={true}
        fullWidth={true}
        variant="contained"
        color="secondary">Secure Checkout <KeyboardArrowRight color="inherit" />
      </Button>
    )
  }
}

export default CheckoutButton;

