'use strict'

import React from 'react';
import { Theme } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Link from 'next/link';
import { withRouter } from 'next/router';

export const styles = (theme: Theme) => createStyles({
  /* Styles applied to the root element. */
  root: {
    transition: theme.transitions.create(['color', 'padding-top'], {
      duration: theme.transitions.duration.short,
    }),
    padding: '6px 12px 8px',
    minWidth: 80,
    maxWidth: 168,
    // color: theme.palette.text.secondary,
    flex: '1',
    '&$iconOnly': {
      paddingTop: 16,
    },
    '&$selected': {
      paddingTop: 6,
      color: theme.palette.primary.main,
    },
  },
  /* Styles applied to the root element if selected. */
  selected: {},
  /* Styles applied to the root element if `showLabel={false}` and not selected. */
  iconOnly: {},
  /* Styles applied to the span element that wraps the icon and label. */
  wrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
  },
  /* Styles applied to the label's span element. */
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    opacity: 1,
    transition: 'font-size 0.2s, opacity 0.2s',
    transitionDelay: '0.1s',
    '&$iconOnly': {
      opacity: 0,
      transitionDelay: '0s',
    },
    '&$selected': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
});

type IProps = any;

class BottomNavigationLink extends React.Component<IProps> {
  handleChange = event => {
    const { onChange, value, onClick } = this.props;

    if (onChange) {
      onChange(event, value);
    }

    if (onClick) {
      onClick(event);
    }
  };

  render() {
    const {
      classes,
      className: classNameProp,
      icon,
      label,
      onChange,
      onClick,
      selected,
      showLabel: showLabelProp,
      value,
      href,
      ...other
    } = this.props;

    // TODO: Determine if selected and add the selected classes..
    const className = classes.root;
    const labelClassName = classes.label;

    return (
      <ButtonBase className={className} focusRipple={true} onClick={this.handleChange} {...other}>
        <Link
          href={href}>
          <span className={classes.wrapper}>
            {icon}
            <span className={labelClassName}>{label}</span>
          </span>
        </Link>
      </ButtonBase>
    );
  }
}


export default withStyles(styles, { name: 'MuiBottomNavigationAction' })(withRouter(BottomNavigationLink));