'use strict'

import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import blue from '@material-ui/core/colors/blue';
// import grey from '@material-ui/core/colors/grey';

function pxToRem(value) {
  return `${value / 16}rem`;
}

const breakpoints = createBreakpoints({});

const overrides = {
  MuiTypography: {
    h1: {
      fontSize: pxToRem(28),
      [breakpoints.up("md")]: {
        fontSize: pxToRem(36)
      }
    },
    h2: {
      fontSize: pxToRem(24),
      [breakpoints.up("md")]: {
        fontSize: pxToRem(32)
      }
    },
    h3: {
      fontSize: pxToRem(23),
      [breakpoints.up("md")]: {
        fontSize: pxToRem(28)
      }
    },
    h4: {
      fontSize: pxToRem(21),
      [breakpoints.up("md")]: {
        fontSize: pxToRem(24)
      }
    },
    h5: {
      fontSize: pxToRem(18),
      [breakpoints.up("md")]: {
        fontSize: pxToRem(21)
      }
    },
    h6: {
      fontSize: pxToRem(16),
      [breakpoints.up("md")]: {
        fontSize: pxToRem(18)
      }
    },
    body1: {
      fontSize: pxToRem(14),

      [breakpoints.up("md")]: {
        fontSize: pxToRem(16)
      }
    },
    body2: {
      fontSize: pxToRem(12),
      [breakpoints.up("md")]: {
        fontSize: pxToRem(12)
      }
    },
    button: {
      fontSize: pxToRem(14),
      [breakpoints.up("md")]: {
        fontSize: pxToRem(16)
      }
    }
  }
}

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  palette: {
    type: "light",
    background: {
      // paper: "#F8F4F4",
      paper: "#FFFFFF",
      default: "#FFFFFF"
    },
    primary: {
      light: blue[300],
      main: '#28AE7B',
      dark: blue[700],
      contrastText: '#FFFFFF',
    },
    secondary: {
      // light: purple[300],
      // main: purple[500],
      // dark: purple[800],
      // contrastText: 'white'
      light: blue[200],
      main: "#0099FF",
      dark: blue[800],
      contrastText: '#FFFFFF'
    }
  },
  overrides
});


// // DARK THEME:
// const theme = createMuiTheme({
//   palette: {
//     type: 'dark',
//     primary: {
//       light: blue[300],
//       main: '#28AE7B',
//       dark: blue[700],
//       contrastText: '#FFFFFF'
//     },
//     secondary: {
//       // light: purple[300],
//       // main: purple[500],
//       // dark: purple[800],
//       // contrastText: 'white'
//       light: blue[200],
//       main: "#0099FF",
//       dark: blue[800],
//       contrastText: '#FFFFFF'
//     }
//   }
// });


/**
 * The color used on mobile browsers to change the color of the url area.
 */
export const browserThemeColor = '#4ca1af';

export default theme;