'use strict'

import React from 'react';
import { Display } from '../components/cart';
import useCart from '../hooks/usecart';

export const Cart = () => {
  const cart = useCart();

  console.log('cart: ', cart);

  if(null === cart) {
    return <Display toggleDisplay={() => {}} isDrawerDisplayed={true} />;
  } else {
    return <Display cart={cart} toggleDisplay={() => {}} isDrawerDisplayed={true} />;
  }
}

export default Cart;

