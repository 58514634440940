'use strict'

import React, { useState, useEffect } from 'react';
import { Theme, BottomNavigation, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import AddAPhoto from '@material-ui/icons/AddAPhotoOutlined';
import Store from '@material-ui/icons/StoreOutlined';
import Orders from '@material-ui/icons/CollectionsOutlined';
import Home from '@material-ui/icons/HomeOutlined';
import { url, LinkType } from '../../paths';
import BottomNavigationLink from './bottomnavigationlink';
import { useScreenSize } from '../../lib/screensize';

// const _Labels = {
//   toggleCart: `Checkout`
// }

/**
 * Mobile cart button is a button that allows mobile users to quickly checkout
 * by simply swiping up or pressing near the bottom of the screen. It's like a floating action button
 * but better. Actually, let's start with a Floating Action Button
 */

interface IProps {
  onCartClick: () => void;
  classes?: any;
}

const _Labels = {
  goToCommission: 'Create',
  goToShop: 'Shop',
  goToCart: 'Cart',
  goToOrders: 'Commissions',
  goToHome: 'Home'
}

const useStyles = makeStyles((theme: Theme) => ({
  cartRoot: {
    transition: theme.transitions.create(['color', 'padding-top'], {
      duration: theme.transitions.duration.short,
    }),
    padding: '6px 12px 8px',
    minWidth: 80,
    maxWidth: 168,
    // color: theme.palette.text.secondary,
    flex: '1',
    '&$iconOnly': {
      paddingTop: 16,
    },
    '&$selected': {
      paddingTop: 6,
      color: theme.palette.primary.main,
    },
  },
  /* Styles applied to the span element that wraps the icon and label. */
  wrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
  },
  /* Styles applied to the label's span element. */
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    opacity: 1,
    transition: 'font-size 0.2s, opacity 0.2s',
    transitionDelay: '0.1s',
    '&$iconOnly': {
      opacity: 0,
      transitionDelay: '0s',
    },
    '&$selected': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  leftButton: {
    flexGrow: 2,
    padding: theme.spacing(2),
    alignItems: 'center',
    textDecoration: 'none !important',
    color: 'inherit',
    background: '#FFFFFF'
  },
  centerButton: {
    flexGrow: 1,
    padding: theme.spacing(2),
    alignItems: 'center',
    background: '#FFFFFF'
  },
  rightButton: {
    flexGrow: 2,
    padding: theme.spacing(2),
    alignItems: 'center',
    textDecoration: 'none !important',
    color: 'inherit',
    background: '#FFFFFF'
  },
  container: {
    color: theme.palette.secondary.contrastText,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 10007,
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    background: '#BCA5AF'
    //background: `linear-gradient(to right bottom, ${startColor}, ${endColor})`
  },
  root: {
    width: '100%',
  }
}));

const BottomNavBar: React.SFC<IProps> = ({ onCartClick}: IProps) => {
  const screenSize = useScreenSize();
  const classes = useStyles({});
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);


  if('desktop' === screenSize || !isClient) {
    return (<span />);
  }

  return (
    <BottomNavigation
      color='secondary'
      className={classes.container}
      showLabels={true}>
      
      <BottomNavigationLink
        label={_Labels.goToOrders}
        value="proofs"
        icon={<Orders fontSize="large" color="inherit" />}
        href="/user/commissions" />
        
      <BottomNavigationLink
        label={_Labels.goToHome}
        value="home"
        icon={<Home fontSize="large" color="inherit" />}
        href="/" />

      <Fab
        style={{position: 'relative', top: '-20%'}}
        aria-label="Cart"
        focusRipple={true}
        onClick={onCartClick}>
        <ShoppingCart fontSize="large" color="inherit" />
      </Fab>

      <BottomNavigationLink
        label={_Labels.goToShop}
        value="shop"
        icon={<Store fontSize="large" color="inherit" />}
        href={url({ type: LinkType.Gallery })}/>

      <BottomNavigationLink
        label={_Labels.goToCommission}
        value="create"
        icon={<AddAPhoto fontSize="large" color="inherit"  />}
        href={url({
          type: LinkType.Commission
        })} />
    </BottomNavigation>
  );
}

export default BottomNavBar;