'use strict'

import * as React from 'react';
import ZeroIcon from '@material-ui/icons/ExposureZero';
import CartIcon from '@material-ui/icons/ShoppingCartTwoTone';
import Typography from '@material-ui/core/Typography';

const _styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '16px',
    padding: '16px'
  } as React.CSSProperties
}

export const Empty = () => (
  <div style={_styles.root}>
    <ZeroIcon />
    <CartIcon fontSize="large" />
    <Typography variant="body1">THIS CART IS EMPTY</Typography>
  </div>
)

export default Empty;