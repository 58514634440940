'use strict'

const API_URL = 'https://qdqqo98h6e.execute-api.us-east-1.amazonaws.com/canary/';
const CONTENT_UPLOAD_BUCKET = 'https://nds-projects.s3.amazonaws.com';

// TODO: Store this in a .env variable like a good security dev. Regenerate before production!
// const SHOPIFY_API_TOKEN = 'ae8782250b2bdda62556329c6e11d40c';
// const SHOPIFY_DOMAIN = 'https://abstract-artwork.myshopify.com';
// const SHOPIFY_GRAPHQL_DOMAIN = `${SHOPIFY_DOMAIN}/api/graphql`;
const APP_NAME = `artmix`;

const URI = 'www.artmix.io';
const CART_URI = 'cart.artmix.io';

const PUBLIC_ASSETS_CDN = `https://d14pujdewqei60.cloudfront.net/`

export {
  API_URL,
  CONTENT_UPLOAD_BUCKET,
  // SHOPIFY_API_TOKEN,
  // SHOPIFY_DOMAIN,
  // SHOPIFY_GRAPHQL_DOMAIN,
  APP_NAME,
  URI,
  CART_URI,
  PUBLIC_ASSETS_CDN
}