'use strict'
import gql from "graphql-tag";

export const GET_CHECKOUT_ID = gql`
  query GetCheckoutId {
    checkoutId @client
  }
`

export const GET_CHECKOUT = gql`
  query getCheckouts($id: ID!)  {
    node(id: $id ) {
      ... on Checkout {
        id
        webUrl
        ready
      }
    }
  }
`

export interface ICheckoutFullResponse {
  node: {
    id: string;
    webUrl: string;
    ready: boolean;
    completedAt: string;
    lineItems: {
      edges: {
        node: {
          title: string;
          id: string;
          variant: {
            id: string;
            image: {
              originalSrc: string;
            }
            price: string;
            selectedOptions: {
              name: string;
              value: string;
            }[]
            title: string;
          }
          quantity: number;
          customAttributes: {
            key: string;
            value: string;
          }[]
        }
      }[]
    }
  }
}

export const GET_CHECKOUT_FULL = gql`
  query getCheckouts($id: ID!)  {
    node(id: $id ) {
      ... on Checkout {
        id
        webUrl
        ready
        completedAt
        lineItems(first:100){
          edges {
            node {
              title
              id
              variant {
                id
                image {
                  originalSrc
                }
                price
                selectedOptions {
                  name
                  value
                }
                title
              }
              quantity
              customAttributes {
                key
                value
              }
            }
          }
        }
      }
    }
  }
`


export const UPDATE_CHECKOUT_ID = gql`
  mutation updateCheckoutId($checkoutId: ID) {
    updateCheckoutId(checkoutId: $checkoutId) @client
  }
`;

export const clientState = {
  defaults: {
    checkoutId: null
  },
  resolvers: {
    Mutation: {
      updateCheckoutId: (_: any, { checkoutId }: {checkoutId: string | null}, { cache }: {cache: any}) => {
        console.log('updateCheckoutId...')
        const toUpdate = Object.assign({}, { checkoutId });
        cache.writeData(toUpdate);
        return null;
      }
    }
  }
}


export default clientState;