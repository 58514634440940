'use strict'
import React from 'react';
import { Button } from '@material-ui/core';

interface IProps {
}

const Display: React.SFC<IProps> = () => (
  <Button disabled={true} variant="contained" color="secondary">Secure Checkout</Button>
);

export default Display;