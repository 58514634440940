'use strict'

import { createGenerateClassName } from '@material-ui/styles';
import { SheetsRegistry } from 'jss';
import theme from './muitheme';
import { Theme } from '@material-ui/core';

export interface PageContext {
  theme: Theme;
  sheetsManager: any;
  generateClassName: any; // not sure what goes here
  sheetsRegistry: SheetsRegistry;
  children?: any;
}

export default function(): PageContext {
  return {
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry(),
    // The standard class name generator.
    generateClassName: createGenerateClassName(),
    children: undefined,
  };
}