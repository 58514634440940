import React from 'react'
import initApolloShopify from './initapolloshopify'
import Head from 'next/head'
import { getDataFromTree } from '@apollo/react-ssr';
import ApolloClient from 'apollo-client';

export default App => {
  return class Apollo extends React.Component {
    static displayName = 'withApollo(App)'
    static async getInitialProps (ctx) {
      const { Component, router } = ctx

      let appProps = {}
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(ctx)
      }

      // Run all GraphQL queries in the component tree
      // and extract the resulting data
      const apollo = initApolloShopify();
      if (typeof window === 'undefined') {
        try {
          // Run all GraphQL queries
          await getDataFromTree(
            <App
              {...appProps}
              Component={Component}
              router={router}
              apolloShopifyClient={apollo}
            />
          )
        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
          console.error('Error while running `getDataFromTree`: ', JSON.stringify(error))
        }

        // getDataFromTree does not call componentWillUnmount
        // head side effect therefore need to be cleared manually
        Head.rewind()
      }

      // Extract query data from the Apollo store
      const apolloState = apollo.cache.extract()

      return {
        ...appProps,
        apolloState
      }
    }

    _apolloClient: ApolloClient<any>;
    constructor (props) {
      super(props)
      this._apolloClient = initApolloShopify(props.apolloState)
    }

    render () {
      return <App {...this.props} apolloShopifyClient={this._apolloClient} />
    }
  }
}