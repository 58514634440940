// This import loads the firebase namespace along with all its type information.
import * as firebase from 'firebase/app';
 
// These imports load individual services into the firebase namespace.
import 'firebase/messaging';
import 'firebase/performance';

import localforage from 'localforage'

const firebaseConfig = {
  apiKey: "AIzaSyAt4zUMGGiXn8UNLxzLEqNN8iyxy-BECT8",
  authDomain: "artmix-a097f.firebaseapp.com",
  databaseURL: "https://artmix-a097f.firebaseio.com",
  projectId: "artmix-a097f",
  storageBucket: "",
  messagingSenderId: "778832946989",
  appId: "1:778832946989:web:85eeb86ab3f667c7"
};

const firebaseInitializer = {
  tokenInlocalforage: async () => {
    return localforage.getItem('fcm_token')
  },

  init: async function () {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig)
    }
    const perf = firebase.performance();

    // try {
    //   if ((await this.tokenInlocalforage()) !== null) {
    //     return false
    //   }

    //   const messaging = firebase.messaging()
    //   await messaging.requestPermission()
    //   const token = await messaging.getToken()

    //   localforage.setItem('fcm_token', token)
    //   console.log('fcm_token', token)
    // } catch (error) {
    //   console.error(error)
    // }
  }
}

export { firebaseInitializer }