'use strict'

import React from 'react';

import { AppBar, Toolbar, Button, Typography, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Link from 'next/link';
import { url, LinkType } from '../../paths';
import AccountIcon from '@material-ui/icons/PermIdentityOutlined';
import AddPhotoIcon from '@material-ui/icons/AddAPhotoOutlined';
import ExploreIcon from '@material-ui/icons/ExploreOutlined';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibraryOutlined';
import useUserCredit from '../usercredit/useusercredit';

/*
 * Initial blue to green
 startColor = #430089
 endColor = #82ffa1

 light blue
 #4ca1af
 #c4e0e5
 */

const startColor = '#4ca1af';
const endColor = '#F8A6CD';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
    background: `linear-gradient(to right bottom, ${startColor}, ${endColor})`
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo: {
    textDecoration: 'none !important',
    color: 'inherit',
  }
}));

interface IProps {
  isMobile: boolean;
  children?: any;
}

const HeaderWithUser = ({
  isMobile
}: IProps) => {
  const classes = useStyles({});
  const userCredit = useUserCredit();

  return (
    <AppBar className={classes.root} position="fixed">
      <Toolbar>
        <Button className={classes.logo} variant={'text'} color="inherit" href="/">
          <Link href={'/'}>
            <Typography variant="h5" color="inherit" noWrap={true}>Artmix</Typography>
          </Link>
        </Button>
        <div className={classes.grow} />
        {
          !isMobile &&
          (<div>
            <Link href={url({
              type: LinkType.Commission
            })}>
              <Button className={classes.logo} variant={'text'} color="inherit" >
                <AddPhotoIcon fontSize="large" />
              </Button>
            </Link>
            <Link
                as={`/shop/canvas`}
                href={`/shop?productType=canvas`}>
              <Button className={classes.logo} variant={'text'} color="inherit" >
                <ExploreIcon fontSize="large" />
              </Button>
            </Link>
            <Link
              href={url({type: LinkType.Orders})}>
              <Button className={classes.logo} variant={'text'} color="inherit" >
                <PhotoLibraryIcon fontSize="large" />
              </Button>
            </Link>
          </div>
          )
        }
        <Link
          href={url({
            type: LinkType.Auth
          })}>
          <span>
            <AccountIcon fontSize="large" />
            {(null !== userCredit) ? userCredit : 0 }
          </span>
        </Link>
      </Toolbar>
    </AppBar>
  )
}

export default HeaderWithUser;