'use strict'

import { GET_CHECKOUT_ID, GET_CHECKOUT_FULL, ICheckoutFullResponse, UPDATE_CHECKOUT_ID } from '../../../lib/providers/apolloshopify/clientstate';
import ApolloClient from 'apollo-client';
import { useQuery, useMutation } from '@apollo/react-hooks';

interface ICheckoutIdData {
  checkoutId: string;
}
  
interface ICheckoutVariables {
  id: string;
}

/**
 * Check if the cart is completed. Inspect the completedAt field
 * 
 * @param data 
 */
const _isCartCompleted = (data?: ICheckoutFullResponse) => {
  return (data && data.node.completedAt);
}

/**
 * _clearCartId
 * 
 * When the user completes a checkout, there cart becomes invalid.
 * Remove their checkoutId from the local cache so they havea new cart.
 */
const _clearCartId = (client: ApolloClient<any>) => {
  alert('clearing cart id');

  client.writeData({
    data: {
      checkoutId: null
    }
  });
}

/**
 * 
 * @param data Cart
 * 
 * Validity conditions:
 * 1. The Cart must exist
 * 2. The Cart can NOT be completed (completedAt !== null)
 */

const _isCartExpired = (data?: ICheckoutFullResponse) => {
  if(data) {
    return ( _isCartCompleted(data) );
  } else {
    return false;
  }
}  

const useCart = () => {
  const [ updateCheckoutId ] = useMutation(UPDATE_CHECKOUT_ID);
  const { called, data, loading, error, startPolling, stopPolling, client } = useQuery<ICheckoutIdData>(GET_CHECKOUT_ID, {
    ssr: false
  });

  // console.log('useCart loadCart', loadCart);
  console.log('useCart called', called);
  console.log('useCart loading', loading);
  console.log('useCart data', data);
  console.log('useCart error', error);
  console.log('useCart startPolling', startPolling);
  console.log('useCart stopPolling', stopPolling);

  // Use "skip" to only call the full checkout query if there is a checkoutId
  const checkoutFullQuery = useQuery<ICheckoutFullResponse, ICheckoutVariables>(GET_CHECKOUT_FULL, {
    variables: {
      id: (data) ? data.checkoutId : null
    },
    pollInterval:500,
    ssr: false,
    skip: (loading || !data || !data.checkoutId || (null === data.checkoutId)),
    fetchPolicy: 'network-only'
  });

  const checkoutData = checkoutFullQuery.data;
  const checkoutLoading = checkoutFullQuery.loading;
  const checkoutError = checkoutFullQuery.error;

  console.log('checkoutData', checkoutData);

  // There is currently no cart assigned to the user, so we just ignore 
  if(!data || null === data.checkoutId) {
    return null;
  } else if( data && checkoutData && checkoutData.node && _isCartExpired(checkoutData) ) {  
    // TODO: REFRESH THE CART, it has already been completed.
    // _clearCartId(client);
    alert('Cart is expired');
    updateCheckoutId(null);

    return null;
  } else {
    if(checkoutLoading) {
      return null;
    } else if(checkoutError) {
      return null;
    } else {
      return checkoutData.node;
    }
  }
}

export default useCart;