'use strict'
import React from 'react';
import Button from '@material-ui/core/Button';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

interface IProps {
  webUrl: string;
  ready: boolean;
  disabled?: boolean;
}

const Display: React.SFC<IProps> = ({ disabled = false, webUrl }: IProps) => (
  <a style={{textDecoration: 'none', color: 'inherit'}} target="_blank" href={webUrl}>
    <Button disabled={disabled} fullWidth={true} variant="contained" color="secondary">Secure Checkout <KeyboardArrowRight color="inherit" /></Button>
  </a>
);

export default Display;