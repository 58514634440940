'use strict'

import React from 'react'
import { ThemeProvider } from '@material-ui/styles';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import App, { Container, AppInitialProps } from 'next/app';
import getPageContext, { PageContext } from '../lib/getpagecontext';
import AppShell from '../components/app';
import { APP_NAME } from '../config';
import withApolloShopify from '../lib/providers/apolloshopify/withapolloshopify';
import sentry from '../lib/providers/sentry';
// import initAmplify from '../lib/providers/amplify/init';
import theme from '../lib/muitheme';
import ApolloClient from 'apollo-client';
import { firebaseInitializer } from '../lib/providers/google/initfirebase';
import { initialize as gaInitialize} from '../lib/providers/google/analytics';
// import { initAmplifyAuth } from '../components/ssrartmixauthenticator';
import { ga } from 'react-ga';

/**
 * Sentry is an error/exception tracker.
 */
const { captureException } = sentry({ release: process.env.SENTRY_RELEASE })

const AppName = APP_NAME;

class NDSApp extends App<{apolloShopifyClient: ApolloClient<any>}> {
  private pageContext: PageContext;

  constructor(props: any) {
    super(props);
    // see material-ui/examples/nextjs/pages/_app.js
    this.pageContext = getPageContext();

    gaInitialize();

    this.state = {
      hasError: false,
      errorEventId: undefined
    }
  }

  static async getInitialProps ({ Component, ctx }): Promise<AppInitialProps> {
    try {
      let pageProps = {}

      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx)
      }

      return { pageProps }
    } catch (error) {
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
      const errorEventId = captureException(error, ctx)
      return {
        pageProps: {
          hasError: true,
          errorEventId
        }
      }
    }
  }

  static getDerivedStateFromProps (props, state) {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined
    }
  }

  static getDerivedStateFromError () {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    ga('send', 'exception', {
      'exDescription': JSON.stringify(error),
      'exFatal': false
    });

    // Sentry captureException
    captureException(error)

    super.componentDidCatch(error, errorInfo)
  }

  async componentDidMount() {

    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }

    // initAmplify();
    // await initAmplifyAuth();
    await firebaseInitializer.init();
  }

  render() {
    const { Component, pageProps, apolloShopifyClient } = this.props;
    const { hasError } = pageProps;

    return hasError ?     (
      <section>
        <h1>There was an error!</h1>
      </section>
    ) : (
      <Container>
        <ThemeProvider theme={theme}>
          <ApolloHooksProvider client={apolloShopifyClient}>
            <AppShell appName={AppName}>
              <Component
                {...this.props}
                style={{height: '100%'}}
                pageContext={this.pageContext}
                {...pageProps} />
            </AppShell>
          </ApolloHooksProvider>
        </ThemeProvider>
      </Container>
    );
  }
}

export default withApolloShopify(NDSApp);

