'use strict'

/**
 * MIGRATING FROM APOLLO BOOST TO APOLLO CLIENT
 * 
 * See this issue for information about the GET_CHECKOUT_ID resolver and cache
 *  issue. (hint: set Resolvers: {})
 * https://github.com/ajhool/nds-client-webapp/issues/62
 */

'use strict'

import ApolloClient from "apollo-client";
import initCache from './cache';
import clientState from './clientstate';
import fetch from 'isomorphic-unfetch';
import isServer from "../../isserver";
// import Auth from '@aws-amplify/auth';
import { ApolloLink, Observable } from "apollo-link";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { withClientState } from "apollo-link-state";
import { useAmplify } from "../../../components/uselazyuserstatus";

let apolloClient: ApolloClient<any> | null = null;

// Polyfill fetch() on the server (used by apollo-client)
if(typeof window === 'undefined') {
  // @ts-ignore
  global.fetch = fetch;
}

interface IHasuraUserInfo {
  token: null | string;
  id: null | string;
}

const _getCognitoToken = async (): Promise<string | null> => {
  // const { loaded, Auth } = useAmplify();
  let jwt: string | null = null;

  if(!isServer()) {
    try {
      const Auth = (await import('@aws-amplify/auth')).default;
      const res = await Auth.currentSession();

      if(res) {
        let idToken = res.getIdToken()
        jwt = idToken.getJwtToken();
      }
    } catch(err) {
      // Amplify throws an error if there is no user, which is stupid. This is not an error, just
      //   somebody is not signed in.
      return null;
    }
  }

  return jwt;
}

/**
 * 
 */
const request = async (operation) => {

  /**
   * On the server we will only make shopify queries which don't need an Authorization header.
   * On the client, we will attach the AWS User's cognito jwt token.
   */

  if(!isServer()){
    const token = await _getCognitoToken();

    if(null !== token) {
      operation.setContext({
        headers: {
          Authorization: token ? `Bearer ${token}` : ``,
          'X-Hasura-Role': 'user'
        }
      })
    } else {
      operation.setContext({
        headers: {
          'X-Hasura-Role': 'anonymous'
        }
      })
    }

  } else {
    operation.setContext({
      headers: {
        'X-Hasura-Role': 'anonymous'
      }
    })
  }
}

const requestLink = new ApolloLink((operation, forward) =>
  new Observable(observer => {
    let handle;
    Promise.resolve(operation)
      .then(oper => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  })
);

/**
 * 
 * @param initialState
 */
const create = (initialState?: any) => {
  const cache = initCache(initialState);

  const client = new ApolloClient({
    // Empty resolvers is necessary to use the GET_CHECKOUT_ID local @client directive
    resolvers: {},
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          // sendToLoggingService(graphQLErrors);
        }
        if (networkError) {
          // logoutUser();
        }
      }),
      requestLink,
      withClientState(clientState),
      new HttpLink({
        uri: 'https://api.artmix.io/v1/graphql',
        credentials: 'include'
      })
    ]),
    ssrMode: typeof window === 'undefined',
    cache,
    // fetch: isBrowser && fetch
  });
  
  return client;
}



const initApolloShopifyBoost = (initialState?: any) => {
  if (typeof window === 'undefined') {
    return create(initialState);
  }

  if (!apolloClient) {
    apolloClient = create(initialState);
  }

  return apolloClient;
}

export default initApolloShopifyBoost;