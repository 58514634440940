'use strict'

import isServer from "./isserver";
import { useState, useEffect } from "react";

export type ScreenSize = 'desktop' | 'tablet' | 'mobile' | 'unknown';

/**
 * HandleResize should be a forceUpdate 
 */
export const watchScreenSize = (window: Window, handleResize: any) => {
  if(!isServer()){
    window.addEventListener('resize', handleResize);
  }
}

/**
 * Minimum size 
 */
export const screenSizes = {
  desktop: 922,
  tablet: 768,
  mobile: 1
}

export const calculateScreenSize = (window?: Window): ScreenSize => {
  if(isServer()) {
    return 'mobile'; // Possibly return unknown
  }
  
  //Desktop/laptop: minWidth: 992
  //Tablet or mobile phone: minWidth: 768 maxWidth: 991
  //Mobile: maxWidth: 767
  //default:
  let screenSize: ScreenSize = 'mobile';

  if(window && window.matchMedia(`(min-width: ${screenSizes.desktop}px)`).matches) {
    screenSize = 'desktop';
  } else if (window && window.matchMedia(`(min-width: ${screenSizes.tablet}px) and (max-width: ${screenSizes.desktop-1}px)`).matches) {
    screenSize = 'tablet';
  } else {
    screenSize = 'mobile';
  }

  return screenSize;
}

const _determineScreenSize = (window?: Window): ScreenSize => {
  let size: ScreenSize;

  //Desktop/laptop: minWidth: 992
  //Tablet or mobile phone: minWidth: 768 maxWidth: 991
  //Mobile: maxWidth: 767
  //default:
  if(window && window.matchMedia(`(min-width: ${screenSizes.desktop}px)`).matches) {
    size = 'desktop';
  } else if (window && window.matchMedia(`(min-width: ${screenSizes.tablet}px) and (max-width: ${screenSizes.desktop-1}px)`).matches) {
    size='tablet';
  } else {
    size='mobile';
  }
  
  return size;
}

/**
 * A react hook that captures the screen size.
 * 
 * Doesn't currently watch the screen size
 */
export const useScreenSize = (): ScreenSize => {
  const [ size, setSize ] = useState(null);

  if(null === size) {
    if(!isServer()){
      setSize(_determineScreenSize(window));
    }
  }

  useEffect(() => {
    if(!isServer()){
      const handleResize = () => {
        setSize(_determineScreenSize(window));
      };

      window.addEventListener('resize', handleResize);

      return () => {
        // React calls this function when it deletes the component
        window.removeEventListener('resize', handleResize);
      };
    }
  })

  return size as ScreenSize;
}