'use strict'

import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { calculateScreenSize, ScreenSize } from '../../../../lib/screensize';
import PrintItem from './printitem';
import CheckoutButton from '../../containers/checkoutbutton';
import { Typography, List, Theme } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import BottomNavBar from '../../../mobilenavbar/bottomnavbar';
import EmptyCart from './empty';

const drawerWidth = '312px'

const styles = (theme: Theme) => createStyles({
  drawer: {
    width: drawerWidth,
    maxWidth: '100%',
    flexShrink: 0,
  },
  mobileDrawer: {
    width: '100%',
    height: '90%',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerPaperMobile: {
    width: '100%',
  },
  toolbar: theme.mixins.toolbar,
  checkoutButton: {
    padding: theme.spacing(3),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
    margin: '12px'
  },
  bottomPadding: {
    padding: '64px'
  }
});


export interface ICart {
  id: string;
  webUrl: string;
  ready: boolean;
  completedAt: string;
  lineItems: {
    edges: {
      node: {
        title: string;
        id: string;
        variant: {
          id: string;
          image: {
            originalSrc: string;
          }
          price: string;
          selectedOptions: {
            name: string;
            value: string;
          }[]
          title: string;
        }
        quantity: number;
        customAttributes: {
          key: string;
          value: string;
        }[]
      }
    }[]
  }
}

interface ICartProps {
  //toggleDisplay: (event: React.MouseEvent<{}>) => void | undefined;
  isDrawerDisplayed: boolean;
  // checkout: CheckoutResource | null;
  cart?: ICart;
  
  toggleDisplay: () => any;
  classes?: any;
  children?: any;
}

interface ICartState {
  isOpen: boolean;
  screenSize: ScreenSize;
}

class Cart extends Component<ICartProps, ICartState> {
  constructor(props: ICartProps){
    super(props);

    this.state = {
      isOpen: false,
      screenSize: 'mobile'
    }

    this.toggleDisplay = this.toggleDisplay.bind(this);
  }

  componentDidMount(){
    // watchScreenSize(window, this.forceUpdate);
    const screenSize = calculateScreenSize(window);
    this.setState({
      screenSize
    })
  }

  toggleDisplay = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const {
      cart,
      classes
    } = this.props;

    const {
      isOpen,
      screenSize
    } = this.state;

    // @ts-ignore
    const launchCheckout = () => goToCheckout();
    
    const toggleDisplay = this.toggleDisplay;
    
    const isCartEmpty = !cart || (cart && (0 === cart.lineItems.edges.length));
    const displayMobileCartButton = ('mobile' === screenSize); // && !isCartEmpty;

    const isCheckoutDisabled = isCartEmpty;

    return (
      <div>
        <Drawer
          className={('desktop' === screenSize) ? classes.drawer : classes.mobileDrawer}
          variant={('desktop' === screenSize) ? 'permanent' : 'temporary'}
          anchor={('desktop' === screenSize) ? 'right' : 'bottom'}
          open={isOpen}
          onClose={toggleDisplay}
          classes={{
            paper: ('desktop' === screenSize) ? classes.drawerPaper : classes.drawerPaperMobile
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div>
            <div className={classes.toolbar} />
            <div className={classes.root}>
              <Typography variant="h6">Shopping Cart</Typography>
              <div className={classes.checkoutButton}>
                <CheckoutButton disabled={isCheckoutDisabled} />
              </div>
              { isCartEmpty && <EmptyCart />}
              { !isCartEmpty && <List dense={false}>
                { cart && cart.lineItems.edges.map(e => <PrintItem checkoutId={cart.id} key={e.node.id} print={e.node} />)}
              </List>
              }
              <div className={classes.bottomPadding} />
            </div>
          </div>
        </Drawer>

        {displayMobileCartButton && <BottomNavBar onCartClick={toggleDisplay} />}
      </div>
    )
  }
}

export default withStyles(styles)(Cart);