'use strict'

import { useState, useEffect } from 'react';
import isServer from '../lib/isserver';
import { useRouter } from 'next/router';
const aws_exports = require('../src/aws-exports.js').default;

/**
 * This is going to be used as a hook for other components, which is very cool!
 */
export const useUserStatus = (): {user: any | null, loading: boolean, identityId: string | null} => {
  const [user, setUser] = useState<any | null>(null)
  const [identityId, setIdentityId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const { loaded, Auth, Hub } = useAmplify();
  
  useEffect(() => {
    console.log('Setting user...');

    if(loaded) {
      let updateUser = async () => {
        try {
          console.log('Calling Auth.currentAuthenticatedUser');
          let user = await Auth.currentAuthenticatedUser()
          let credentials = await Auth.currentCredentials();
          let identityId = credentials.identityId;

          console.log('set user and identityId: ', identityId);
  
          // I think user should be CognitoUser but it doesn't seem like a match in code
          setUser(user);
          setIdentityId(identityId);
          _setFlag(IsLoggedInState.AUTHENTICATED);
          setLoading(false);
        } catch {
          setUser(null)
          setIdentityId(null);
          _setFlag(IsLoggedInState.NOT_LOGGED_IN)
          setLoading(false);
        }
      }
      Hub.listen('auth', updateUser) // listen for login/signup events
      updateUser() // check manually the first time because we won't get a Hub event
      return () => Hub.remove('auth', updateUser) // cleanup
    } else {
      return () => {}
    }
  }, [loaded]);

  return {
    user,
    loading,
    identityId
  }
}

export enum IsLoggedInState {
  'AUTHENTICATED',
  'LOGGED_IN_WAITING_AUTH',
  'NOT_LOGGED_IN',
  'UNSURE'
}

const _guessInitialLoginStatus = (): IsLoggedInState => {
  if( isServer() ){
    return IsLoggedInState.UNSURE;
  } else {
      const flagValue = localStorage.getItem(`isUserLoggedIn`);
      // If the flag is null, then the user probably isn't logged in (if you add this to your code, the user will experience a one-time-only bad guess). Otherwise, check the flag's value.
      const loginGuess = (null !== flagValue) && (flagValue === 'true');
      return (loginGuess) ? IsLoggedInState.LOGGED_IN_WAITING_AUTH : IsLoggedInState.NOT_LOGGED_IN; 
  }
}

const _setFlag = (value: IsLoggedInState) => {
  const shouldUpdateFlag = (value === IsLoggedInState.AUTHENTICATED) || (value === IsLoggedInState.NOT_LOGGED_IN);
  if(shouldUpdateFlag) {
    const asFlag = (value === IsLoggedInState.AUTHENTICATED);
    const flagAsString = (asFlag) ? 'true' : 'false';
    localStorage.setItem('isUserLoggedIn', flagAsString);
  }
}

type AuthEvents = 'signIn' | 'signUp' | 'signOut' | 'signIn_failure' | 'configured';

/**
 * We set a localStorage flag if the user has visited the site.
 * If they haven't visited
 * 
 * The function _guessLoggedInState is more nuanced as it returns a loading value during
 * SSR so we don't flash a Welcome screen when a user has signed in.
 */
export function useIsFirstVisit(): boolean {
  if( isServer() ){
    return true;
  } else {
    const flagValue = localStorage.getItem(`isUserLoggedIn`);
    return (null === flagValue) || (flagValue !== 'true');
  }
}

let Amplify;
let Auth;
let Storage;
let Hub;

/**
 * Goal:
 * Check localStorage to see if the user has visited this site on their browser/device before.
 * If there is no localStorage flag, check to see if "code" exists in the url, which means we
 * have come from the Cognito Hosted UI and the user has just logged in/signed up.
 * 
 * If neither of the above is true, don't load amplify because we don't need it.
 * 
 * If either of the above is true, load amplify and it will automatically determine the user
 * based on tokens or the url code.
 */
export const useAmplify = () => {
  const isFirstVisit = useIsFirstVisit();
  const [loaded, setLoaded] = useState(false);
  const router = useRouter();

  // Note: Amplify removes code after it processes it the first time. Perfect, that means code should only
  //   exist in URL when we are arriving here after a cognito login or signup.
  const code = ( router.query && typeof router.query.code !== 'undefined' ) ? router.query.code : null;

  /**
   * TODO: Check router params for "CODE" which indicates that we should configure 
   * amplify because we have arrived here after visiting the Cognito Hosted UI!
   */

  useEffect(() => {
    const asyncModuleLoader = async () => {
      if(isFirstVisit && (null === code)){
        setLoaded(false);
      } else {
        if(!Amplify || !Auth || !Storage) {
          // @ts-ignore;
          window.LOG_LEVEL = 'debug';

          Amplify = (await import('@aws-amplify/core')).default;
          Hub = (await import('@aws-amplify/core').then(mod => mod.Hub));
          Auth = (await import('@aws-amplify/auth')).default;
          Storage = (await import('@aws-amplify/storage')).default;
          
          Amplify.configure(aws_exports);
          Auth.configure(aws_exports);
          Storage.configure(aws_exports);
        }

        setLoaded(true);
      }
    }
    
    asyncModuleLoader();
  }, [code]);

  return {
    loaded,
    Amplify,
    Auth,
    Storage,
    Hub
  }
}