'use strict'

import { useState } from "react";
import { useGetUserCreditQuery } from "../../src/generated/graphql";

/**
 * IMPORTANT NOTE ABOUT HOOKS:
 * 
 * Remember, react hooks don't reuse state... they reuse state logic. If two components
 *  both share this useUserCredit hook, they will share the state logic but might have different
 *  states. React hooks are not a singleton, although they might be able to wrap a singleton that is persisted
 *  somewhere else, like in Apollo or redux.
 */



/**
 * useUserCredit is a react hook that provides the current user's credit. It also manages
 * the lifecycle for user credit.
 * 
 * Lifecycle:
 * User Signs Up.
 * - Create UserCredit from the client (deposit initial credit)
 * - User executes transfers -- backend depletes credit, this hook updates credit amount.
 * - User purchases credits -- backend adds credit, this hook updates.
 */

const useUserCredit = () : null | number => {
  const [credit, setCredit] = useState<null | number>(null);
  const userCreditQuery = useGetUserCreditQuery({
    ssr: false,
    fetchPolicy: 'no-cache'
  });
  
  if(userCreditQuery.error || userCreditQuery.loading || userCreditQuery.data.user_credit.length === 0) {
    if(null !== credit) {
      setCredit(null);
    }
  } else {
    const val = userCreditQuery.data.user_credit[0].val;
    if(val !== credit) {
      setCredit(val);
    }
  }

  return credit;
}

export default useUserCredit;