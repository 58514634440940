'use strict'

import React from 'react';
import gql from "graphql-tag";
import { IconButton } from '@material-ui/core';
import { GET_CHECKOUT_FULL, GET_CHECKOUT } from '../../../lib/providers/apolloshopify/clientstate';
import Delete from '@material-ui/icons/HighlightOff';
import { useMutation } from '@apollo/react-hooks';

/**
 * TODO:
 * 
 * 1. Currently, Sequential queries are used to find the checkoutId and then the corresponding checkout.
 *    I believe that a "GraphQL Connection" should be used, instead, to connect the checkoutId to the corresponding
 *    checkoutNode. This can remove the complicated Nested query.
 */

/**
 * Mutation for adding the item to a cart.
 */

const CHECKOUT_LINE_ITEMS_REMOVE = gql`
  mutation checkoutLineItemsRemove($lineItemIds: [ID!]!, $checkoutId: ID!) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
      userErrors {
        field
        message
      }
      checkout {
        id
      }
    }
  }
`;

export interface ILineItemsRemoveVariables {
  lineItemIds: string[];
  checkoutId: string;
}

interface IProps {
  itemIds: string[];
  checkoutId: string;
}

/**
 * If the cart exists, add Items to cart.
 * Otherwise, create the cart.
 */

const _RemoveLineItem: React.SFC<ILineItemsRemoveVariables> = ({ lineItemIds, checkoutId }) => {
  const [ checkoutLineItemsRemove ] = useMutation<ILineItemsRemoveVariables>(CHECKOUT_LINE_ITEMS_REMOVE, {
    variables: {
      lineItemIds, checkoutId
    },
    refetchQueries: [
      {
        query: GET_CHECKOUT_FULL,
        variables: { id: checkoutId }
      },
      {
        query: GET_CHECKOUT,
        variables: { id: checkoutId }
      }
    ]
  });

  return (
    <div style={{color: "#DDDDDD"}}>
      <IconButton color="inherit" aria-label="Delete" onClick={() => checkoutLineItemsRemove({
          variables: { 
            lineItemIds,
            checkoutId
          }
        })}>
        <Delete />
      </IconButton>
    </div>
  )
      };
 
export const RemoveButton: React.SFC<IProps> = ({
  itemIds,
  checkoutId
}) => (
  <div>
    <_RemoveLineItem lineItemIds={itemIds} checkoutId={checkoutId}/>
  </div>
);

export default RemoveButton;