'use strict'
import { TProductType, DefaultType, fromUrl, productToUrl } from './lib/productmanifest';

const PRODUCT_TAG_IDENTIFIER = '-producttag';

/**
 * / Landing page
 * /about-us
 * /commissions/build
 * /checkout
 * /dev-sketchbook
 * /gallery
 * /login
 * 
 * /:productType/:productTag
 * /:productType/
 */

export enum LinkType {
  ProductTag = 'productTag',
  Collection = 'collection',
  ProductName = 'productname',
  Gallery = 'gallery',
  Tag = 'tag',
  Commission = 'commission',
  CommissionResults = 'commissionresults',
  Auth = 'auth',
  Orders = 'commissions'
}

interface IGoToGallery {
  type: LinkType.Gallery;
  productType?: TProductType; // Default art-prints
  tag?: string; // Default none
}

interface IGoToProductTag {
  type: LinkType.ProductTag;
  productTag: string;
  productType?: TProductType;
};

interface IGoToCollection {
  type: LinkType.Collection;
  collection: string;
  productType?: TProductType;
}

/**
 * Should really be "product handle"
 * 
 * Navigates to an exact product -- eg. Wild Bear Canvas Print
 * 
 * /product/wild-bear-canvas/
 */
type IGoToProductName = {type: LinkType.ProductName} & GoToProductNamePayload;


interface GoToProductNamePayload {
  productName: string;
}

interface IGoToTag {
  type: LinkType.Tag;
  tag: string;
  productType?: TProductType;
}

interface IGoToCommission {
  type: LinkType.Commission;
  commissionId?: string;
  productId?: string;
}

interface IGoToAuth {
  type: LinkType.Auth;
}

interface IGoToOrders {
  type: LinkType.Orders;
}

type GoToParams = 
  IGoToProductTag |
  IGoToCollection |
  IGoToTag |
  IGoToProductName |
  IGoToGallery |
  IGoToCommissionResults |
  IGoToCommission |
  IGoToOrders |
  IGoToAuth;

interface IGoToCommissionResults {
  type: LinkType.CommissionResults;
  commissionId: string;
  resultId?: string;
  productId?: string;
  orderId?: string;
  lineItemId?: string;
}

const _commissionItem = ({commissionId, resultId, orderId, lineItemId}: IGoToCommissionResults): string => {
  const baseUri = `/user/commission/${commissionId}`;
  if(resultId) {
    if(orderId) {
      // Confirmation Selection Page
      return `${baseUri}?resultid=${resultId}&orderid=${orderId}&lineitemid=${lineItemId}`;
    } else {
      // Add Order to Result Page
      return `${baseUri}?resultid=${resultId}`;
    }
  } else {
    if(orderId) {
      // Result Picker Page (for already paid order)
      return `${baseUri}?orderid=${orderId}&lineitemid=${lineItemId}`;
    } else {
      return baseUri;
    }
  }
}

const _gallery = ({productType = DefaultType, tag}: IGoToGallery): string => {
  const pt = _encodeProductType(productType);
  return (tag) ? `/shop/${pt}/${tag}` : `/shop/${pt}`;
}

const _productName = ({productName}: IGoToProductName): string => {
  const name = _encodeProductName(productName);
  return `/product/${name}`;
}
  
const _collection = ({ collection }: IGoToCollection): string => {
  const path = _encodeCollection(collection);
  return `/collection/${path}`;
}

const _tag = ({tag = DefaultType, productType}: IGoToTag): string => {
  return `/shop/${productType}/${tag}`;
}

const _encodeCollection = (collection: string): string => {
  // todo: encode collection
  return collection;
}

const _encodeProductName = (productName: string): string => {
  const name = productName.toLowerCase();
  return name;
}

const _encodeProductType = (productType: TProductType): string => {
  return productToUrl(productType);
}

const _auth = (): string => {
  return '/user'
}

const _orders = (): string => {
  return '/user/commissions';
}

/**
 * 
 */
export const encodeProductTag = (productTag: string): string => {
  return productTag.split(PRODUCT_TAG_IDENTIFIER)[0];
}

/**
 * TODO: Store product type and product type handle as key value pairs
 */

export const url = (args: GoToParams): string => {
  let url;

  switch(args.type){
    case LinkType.Gallery: 
      url = _gallery(args);
      break;
    case LinkType.Collection:
      url = _collection(args)
      break;
    case LinkType.ProductName:
      url = _productName(args);
      break;
    case LinkType.Tag:
      url = _tag(args);
      break;
    case LinkType.Commission:
      url = `/create`;
      break;
    case LinkType.CommissionResults:
      url = _commissionItem(args);
      break;
    case LinkType.Auth:
      url = _auth();
      break;
    case LinkType.Orders:
      url = _orders();
      break;
    default:
      url = '';
      break;
  }

  return url;
}

/**
 * 
 */
export const productTypeFromPath = (pathParam: string): TProductType => {
  const parsed = fromUrl(pathParam);
  return (parsed) ? parsed.productType : DefaultType;
}

export const tagFromPath = (pathParam?: string): string => {
  return pathParam ? pathParam.toLowerCase() : '';
}

/**
 * productTag from path 
 */
export const productTagFromPath = (pathParam: string): string => {
  return `${pathParam}${PRODUCT_TAG_IDENTIFIER}`.toLowerCase();
}

  /**
   * parse a query string.
   * Can receive as parameter the full url or `url.split('?')[1]` or `location.search` or `location.hash`.
   *
   * @param {String} str - the string containing the query string to be parsed.
   * @param {String} [delimiter] - if undefined (no value) the default ampersand '&' will be the pairs separator.
   * Else you can provide an alternative separator, for instance the semicolon ';' in case of URLs embedded in HTML.
   * @param {String} [eq] - key/pair separator.
   * @returns {Object} parsed object (use as a dictionary)
   */
export const parseQueryString = (window: Window | string): {[key: string]: string} => {
  const _decode = (v: string): string => {
    if (v) { return decodeURIComponent(v); }
    return v;
  }
  
  const delimiter = '&';
  const eq = '=';
  let str = (typeof window === 'string') ? window : window.location.search;

  let i;

    // create an object with no prototype
  let dic = Object.create(null);

    // step 0: sanity checks
  if (typeof str !== 'string') { return dic; }

  // detect if we have or not a query string
  i = str.indexOf('?');
  if (i < 0 && str.indexOf(eq) < 0) {
    return dic;
  }

  // step 1: prepare query string
  // split by '?'
  if (i >= 0) {
    str = str.substr(i + 1);
  }

  // trim space (see MDN polyfill), ?, # and & (allow passing location.search or location.hash as parameter)
  str = str.replace(/^[\s\uFEFF\xA0\?#&]+|[\s\uFEFF\xA0&]+$/g, '');

  // remove anchor [ "#" fragment ]
  i = str.lastIndexOf('#');
  if (i > 0) {
      str = str.substr(0, i);
  }

  // step 2: split by key/value pair
  var parts = str.split(delimiter);

  for (i = 0; i < parts.length; ++i) {
      // step 3: split key/value pair
      var s = parts[i].replace(/\+/g, ' ');
      var p = s.indexOf(eq);
      var key, val;

      // key must exist
      if (p === 0 || s.length === 0) {
          continue;
      }

      // split
      if (p < 0) {
        key = _decode(s);
        val = null; // missing `=` should be `null`:
      } else {
        key = _decode(s.substr(0, p));
        val = _decode(s.substr(p + 1));
      }

      // check existing dic and add
      var e = dic[key];

      // step 4: add to dictionary
      if (e === undefined) {
        dic[key] = val;
      } else if (Array.isArray(e)) {
        e.push(val);
      } else {
        dic[key] = [e, val];
      }
  }
  return dic;
}