'use strict'

import React, { useState, useEffect } from 'react';
import { useScreenSize } from '../../lib/screensize';
import { useUserStatus } from '../uselazyuserstatus';
import NoUser from './nouser';
import WithUser from './withuser';
import BeforeUserCheck from './beforeusercheck';

/**
 * 
 * 
 * 
 * This component is changing. Instead of being the core component, it is more of a router.
 * 
 * 1. It should check the screenSize to decide which type of header to display.
 * 2. It should display the appropriate header for the screen size.
 * 
 */

const Header = () => {
  const screenSize = useScreenSize();
  const { loading, user } = useUserStatus();
  const [isClient, setIsClient] = useState(false);
  
  useEffect(() => {
    setIsClient(true);
  }, []);

  if( !isClient ){
    return <BeforeUserCheck />
  }

  const isMobile = (screenSize === 'mobile');

  return (null !== user) ? <WithUser isMobile={isMobile} /> : <NoUser isMobile={isMobile} />;
}

export default Header;