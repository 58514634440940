'use strict'

import React from 'react';
import Link from 'next/link';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { url, LinkType } from '../../paths';

//@ts-ignore
import { SocialIcon } from 'react-social-icons';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    cursor: 'pointer'
  },
  linkList: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
    width: '12em'
  },
  socialIcon: {
    margin: '24px',
    width: '25px',
    height: '25px',
  },
  footer: {
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    minHeight: '25vh',
    paddingBottom: '24px',
  },
  footerTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  footerBody: {
    display: 'flex',
    flexdirection: 'row',
    flexWrap: 'wrap'
  },
  footerContent: {
    flex: 1
  },
  logo: {
    maxWidth: '200px',
    width: '50%'
  }
}));

/**
 * TODO: These blocks should be horizontal unless the screen is skinny,
 * then they should be stacked vertically.
 */

/**
 * The Footer is reactive.
 *  - Large Screen: horizontal layout direction
 *  - Small Screen: vertical layout direction
 */
// const determineLayoutDirection = () => {

// }

// .footerContent {
//   flex: 1;
// }

const Footer = () => {
  const classes = useStyles({});

  return (
    <footer className={classes.footer}>
      <div className={classes.footerContainer}>
        <div className={classes.footerTop}>
          <SocialIcon url="http://facebook.com/aidan.hoolachan" className={classes.socialIcon} />
          <SocialIcon url="http://instagram.com/ai.artstudio" className={classes.socialIcon} />
          <SocialIcon url="http://twitter.com/ajhool" className={classes.socialIcon} />
        </div>
        <div className={classes.footerBody}>
          <div className={classes.footerContent}>
            <img className={classes.logo} src="/static/img/artmix_text_logo.png" />
            <div>All Rights reserved</div>
            <div>Aidan Hoolachan &copy;</div>
            <div>Courtesy of ArtMix</div>
          </div>
          <nav className={classes.linkList}>
            <h3>Shop</h3>
            <Link href={url({type: LinkType.Commission})}><span className={classes.link}>Make Art</span></Link>
            <Link href={url({type: LinkType.Gallery})}><span className={classes.link}>Browse Gallery</span></Link>
          </nav>
          <aside className={classes.linkList}>
            <h3>Company</h3>
            <Link href={'/about'}><span className={classes.link}>About Us</span></Link>
            <Link href={'/careers'}><span className={classes.link}>Careers</span></Link>
          </aside>
          <aside className={classes.linkList}>
            <h3>Resources</h3>
            <Link href={"/reviews"}><span className={classes.link} >Reviews</span></Link>
            <Link href={"/contact"}><span className={classes.link} >Contact Us</span></Link>
          </aside>
          <aside className={classes.linkList}>
            <h3>Policies</h3>
            <Link href={"/policies/terms"}><span className={classes.link}>Terms of Service</span></Link>
            <Link href={"/policies/refund"}><span className={classes.link}>Refunds</span></Link>
            <Link href={"/policies/privacy"}><span className={classes.link}>Privacy Policy</span></Link>
          </aside>
        </div>
      </div>
    </footer>
  )
};

export default Footer;
