// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9ebb254c-31ad-460e-94ca-6305fdf89cfc",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_OYamHPXMu",
    "aws_user_pools_web_client_id": "6l40b9if6t1m4e96tui0tfrco3",
    "oauth": {
        "domain": "artmix-beta-beta.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.artmix.io/create/",
        "redirectSignOut": "https://www.artmix.io/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_user_files_s3_bucket": "artmixcustomerstorage-1557894586-beta",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
