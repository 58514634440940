import React from 'react';
import { 
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import Folder from '@material-ui/icons/Folder';
import RemoveButton from '../../containers/removebutton';

/**
 * printItem might be more aptly named "lineItem"
 * 
 * It represents a product variant and quantity that has
 * been added to the cart.
 */

interface IPrintItem {
  title: string;
  id: string;
  variant: {
    id: string;
    image: {
      originalSrc: string;
    }
    price: string;
    selectedOptions: {
      name: string;
      value: string;
    }[]
    title: string;
  }
  quantity: number;
  customAttributes: {
    key: string;
    value: string;
  }[]
}

interface IProductOrderDisplayProps {
  key: string | number;
  print: IPrintItem;
  onQuantityChange?: (key: number | string, value: number)=>void;
  onRemoveItem?: Function;
  checkoutId: string;

  children?: any;
}

const ProductOrderDisplay= ({
  print, 
  checkoutId
}: IProductOrderDisplayProps) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar src={print.variant.image.originalSrc} alt={print.title}>
        <Folder />
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={`${print.title}`}
      secondary={`${print.quantity} - ${print.variant.title} - $${print.variant.price}`} 
    />
    <ListItemSecondaryAction>
      <RemoveButton itemIds={[print.id]} checkoutId={checkoutId} />
    </ListItemSecondaryAction>
  </ListItem>
);

export default ProductOrderDisplay;