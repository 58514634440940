import { persistCache } from 'apollo-cache-persist';
import isServer from '../../isserver';
import { InMemoryCache } from 'apollo-cache-inmemory';

/**
 * TODO: SSR: Add 
 */
// export const cache = new InMemoryCache();

const initCache = (initialState?: any) => {
  const cache = new InMemoryCache().restore(initialState || {});
  
  /**
   * Cache uses localStorage to save data.
   * 
   * This cache is used by Apollo (graphql client).
   */
  if(!isServer()) {
    persistCache({
      cache,
      storage: window.localStorage // as PersistentStorage<PersistedData<NormalizedCacheObject>>
    });
  }
  
  return cache;
}

export default initCache;