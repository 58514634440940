'use strict'

import React from 'react';
import Footer from './footer/';
import Header from './header';

import CssBaseline from '@material-ui/core/CssBaseline';
import { Theme } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import Cart from './cart/containers/cart';

interface IProps {
  appName: string;
  classes: any;
  children: any;
}

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center'
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    padding: theme.spacing(2)
  },
  sideways: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  toolbar: theme.mixins.toolbar
});

/**
 * TODO: ReactLoadable might be a slightly prettier implementation.
 */

const App: React.SFC<IProps> = ({ appName, classes, children }: IProps) => (
  <React.Fragment>
    <CssBaseline />
    <div className={classes.root}>
      <Header />
      <div className={classes.toolbar} />
      <div className={classes.sideways}>
        <main className={classes.content}>
          { children }
          <Footer />
        </main>
        <Cart />
      </div>
    </div>
  </React.Fragment>
);

export default withStyles(styles)(App);
